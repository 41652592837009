const EMOJIS = [
    'alien.svg',
    'artist-palette.svg',
    'atom-symbol_accent.svg',
    'atom-symbol.svg',
    'avocado.svg',
    'backhand-index-pointing-right.svg',
    'balloon_accent.svg',
    'balloon.svg',
    'banana.svg',
    'beaming-face-with-smiling-eyes_accent.svg',
    'beaming-face-with-smiling-eyes.svg',
    'blossom.svg',
    'call-me-hand.svg',
    'candy.svg',
    'cat-face.svg',
    'cherries.svg',
    'cloud.svg',
    'crossed-fingers.svg',
    'dizzy.svg',
    'doughnut.svg',
    'face-with-tongue.svg',
    'fireworks.svg',
    'fish-cake-with-swirl.svg',
    'flexed-biceps.svg',
    'four-leaf-clover_accent.svg',
    'four-leaf-clover.svg',
    'game-die.svg',
    'globe-showing-europe-africa_accent.svg',
    'globe-showing-europe-africa.svg',
    'grinning-cat-face-with-smiling-eyes.svg',
    'grinning-squinting-face.svg',
    'headphone.svg',
    'hot-beverage.svg',
    'kissing-face-with-smiling-eyes.svg',
    'lion-face.svg',
    'octopus.svg',
    'ok-hand.svg',
    'oncoming-fist.svg',
    'optical-disk.svg',
    'peace-symbol.svg',
    'pig-face.svg',
    'pile-of-poo.svg',
    'right-facing-fist.svg',
    'rocket.svg',
    'rosette.svg',
    'see-no-evil-monkey.svg',
    'sign-of-the-horns.svg',
    'skull.svg',
    'snowflake.svg',
    'soccer-ball.svg',
    'strawberry.svg',
    'sun-with-face.svg',
    'tanabata-tree.svg',
    'teacup-without-handle_accent.svg',
    'teacup-without-handle.svg',
    'volleyball.svg',
    'whale.svg',
    'white-flower.svg',
    'winking-face-with-tongue.svg',
    'winking-face.svg',
    'yellow-heart.svg',
    'yin-yang.svg',
]

const EMOJIS_MOBILE = [
    'alien.svg',
    'atom-symbol_accent.svg',
    'beaming-face-with-smiling-eyes.svg',
    'doughnut.svg',
    'four-leaf-clover_accent.svg',
    'game-die.svg',
    'globe-showing-europe-africa_accent.svg',
    'grinning-cat-face-with-smiling-eyes.svg',
    'peace-symbol.svg',
    'sign-of-the-horns.svg',
    'skull.svg',
    'whale.svg'
]

export { EMOJIS, EMOJIS_MOBILE }